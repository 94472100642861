<template>
  <div>
    <van-nav-bar :fixed="true" :placeholder="true" :title="ruleTitle" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div v-html="dataHtml" style="padding: 12px"></div>
  </div>
</template>

<script>
import API from '@/common/axios';
import { Toast } from 'vant';
export default {
  data() {
    return {
      dataHtml: "",
      ruleTitle: ""
    };
  },
  mounted() {
    document.title = "规则";
    this.getData();
  },
  methods: {
    onClickLeft() {
      this.$router.replace('/propertyManager/home');
    },
    getData() {
      API({
        url: "/h5/property/getPerformanceIntro.do",
        data: {}
      }).then(res => {
        if (res.success == 1) {
          if (res.data.ruleId) {
            document.title = res.data.ruleTitle;
            this.ruleTitle = res.data.ruleTitle;
            this.dataHtml = res.data.ruleContent;
          }
        } else {
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        Toast("服务异常，请刷新页面重试！");
      });
    }

  }
};
</script>

<style lang="scss" scoped>
/deep/ .van-icon {
  color: black;
}

/deep/ .van-nav-bar__text {
  color: black;
}
</style>